// Social Sharing Init and Config
import FbsSharingService from '@forbes/fbs-sharing';
import { serverData } from './shared/clientConfigService';

const fbsShareInstance = new FbsSharingService();

const ShareType = {
	facebook: 'facebook',
	twitter: 'twitter',
	linkedIn: 'linkedin',
	google: 'google',
	email: 'email',
};

function getShareInfo() {
	const title = (document.head.getElementsByTagName('title')[0] || {}).textContent || '';
	const description = document.head.querySelector('[name=description]').getAttribute('content') || '';

	return { // TODO: Get meta data more efficiently
		title,
		description,
		uri: `https://www.forbes.com${window.location.pathname}`,
	};
}

function customTwitterShare(meta) {
	const encodedUri = encodeURIComponent(meta.uri);
	const encodedText = encodeURIComponent(meta.description);
	const windowConfig = 'width=400,height=500,resizable=false';
	const link = `https://twitter.com/intent/tweet?url=${encodedUri}&text=${encodedText}`;

	window.open(link, 'Article Share', windowConfig).focus();
}

// Social platforms
const shareFb = [...document.querySelectorAll('.facebook')];
shareFb.forEach((element) => {
	element.addEventListener('click', (event) => {
		fbsShareInstance.shareArticleOnSocial(event, ShareType.facebook, getShareInfo());
	});
});

const shareLi = [...document.querySelectorAll('.linkedin')];
shareLi.forEach((element) => {
	element.addEventListener('click', (event) => {
		fbsShareInstance.shareArticleOnSocial(event, ShareType.linkedIn, getShareInfo());
	});
});

const shareTw = [...document.querySelectorAll('.twitter')];
shareTw.forEach((element) => {
	element.addEventListener('click', (event) => {
		if (serverData.useCustomSharing) {
			customTwitterShare(getShareInfo());
		} else {
			fbsShareInstance.shareArticleOnSocial(event, ShareType.twitter, getShareInfo());
		}
	});
});

const shareEmail = [...document.querySelectorAll('.email')];
shareEmail.forEach((element) => {
	element.addEventListener('click', (event) => {
		fbsShareInstance.shareArticleOnSocial(event, ShareType.email, getShareInfo());
	});
});
