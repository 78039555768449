import throttle from '../shared/throttle';

let stickWrappers;
let minHeight;
let firstStickyWrapper;
let secondStickyWrapper;
let streamStart;
let streamArticleAd;
let teamsProfile;

/**
* Sets the height for the first sticky ad's wrapper
*/
const setFirstContainerHeight = () => {
	if (!teamsProfile) {
		minHeight = streamStart.getBoundingClientRect().top - firstStickyWrapper.getBoundingClientRect().top;
	} else {
		minHeight = streamArticleAd.getBoundingClientRect().bottom - firstStickyWrapper.getBoundingClientRect().top;
	}
	firstStickyWrapper.setAttribute('style', `min-height: ${minHeight}px`);
};

/**
 * Sets the height for the second sticky ad's wrapper
 */
const setSecondContainerHeight = () => {
	if (!teamsProfile) {
		minHeight = streamArticleAd.getBoundingClientRect().bottom - streamStart.getBoundingClientRect().top;
		secondStickyWrapper.setAttribute('style', `min-height: ${minHeight + 3}px`);
	}
};

document.addEventListener('DOMContentLoaded', () => {
	streamStart = document.querySelector('.profile-news');
	streamArticleAd = document.querySelector('.profile-news__adv');
	stickWrappers = document.querySelectorAll('.fbs-ad--recx--sticky');
	teamsProfile = document.querySelector('.team-valuations');
	firstStickyWrapper = stickWrappers[0];
	secondStickyWrapper = stickWrappers[1];

	if (firstStickyWrapper || secondStickyWrapper) {
		window.addEventListener('scroll', throttle(setFirstContainerHeight, 100));
		window.addEventListener('scroll', throttle(setSecondContainerHeight, 100));
	}

	setFirstContainerHeight();
	setSecondContainerHeight();
});
