/**
* Handles simple element creation.
@param {String} [type='div'] The type of element you'd like to create.
@param {Object} [attributes={}] The attributes you'd like to add to new element.
@return {Element} Returns an element with all attributes added.
*/
function createSimpleElement(type = 'div', attributes = {}) {
	const simpleElement = document.createElement(type);
	Object.keys(attributes).forEach((key) => simpleElement.setAttribute(key, attributes[key]));
	return simpleElement;
}

export default createSimpleElement;
