import { isMobile } from '../../shared/is-mobile';
import createSimpleElement from '../../shared/domUtilities';

/**
 * Removes existing badge metrics
 * @param {HTMLDocument} context The HTML element for a specific page
 */
const removeExistingBadgeMetrics = (context = document) => {
	const badge = context.querySelector('.bertie-badge') || document.querySelector('.content-data--hidden .bertieBadge');
	const firstPipe = context.querySelector('.vert-pipe.first') || document.querySelector('.content-data--hidden .vert-pipe.first');

	if (badge) {
		badge.remove();
	}

	if (firstPipe) {
		firstPipe.remove();
	}
};

/**
 * Removes existing time metrics
 * @param {HTMLDocument} context The HTML element for a specific page
 */
const removeExistingTimeMetrics = (context = document) => {
	const timeElement = context.querySelector('time') || document.querySelector('.content-data--hidden time');
	const timeSpan = context.querySelector('span.time') || document.querySelector('.content-data--hidden span.time');

	if (timeElement) {
		timeElement.remove();
	}

	if (timeSpan) {
		timeSpan.remove();
	}
};

/**
 * Sets dates as EST dates
 * @param {String} date The Date in question
 */
const setEstDate = (date = '') => {
	const localDate = date ? new Date(date) : new Date();
	localDate.setTime(localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000);

	const offset = -300;
	const estDate = new Date(localDate.getTime() + offset * 60 * 1000);
	return estDate;
};

/**
 * Creates and Adds the Live Label if expiration date has not passed
 * @param {HTMLDocument} context The HTML element for a specific page
 */
const setLiveLabel = (context = document) => {
	const liveLabel = createSimpleElement('div', {
		class: 'content-data_live-label',
		style: 'animation: liveAlert 2s infinite ease; background-color: #D8361E; color: #FFFFFF; display: inline-block; font: 600 10px/12px "Work Sans", sans-serif; letter-spacing: 0.5px; padding: 6px 12px; text-transform: uppercase;',
	});
	liveLabel.innerText = 'live';

	const liveStyleTag = createSimpleElement('style');
	liveStyleTag.innerText = '@keyframes liveAlert { 0% { background-color: #D8361E } 50% { background-color: #E26957 } 100% { background-color: #D8361E } }';

	removeExistingBadgeMetrics(context);
	context.parentElement.insertBefore(liveStyleTag, context);
	context.parentElement.insertBefore(liveLabel, context);
};

/**
 * Set Minute label for live template
 * @param {Number} diffMinutes minutes
 * @return {String} Minute label
 */
const setMinuteLabel = (diffMinutes) => {
	let minuteLabel;
	switch (diffMinutes) {
		case 0:
			minuteLabel = 'Just now';
			break;
		case 1:
			minuteLabel = `${diffMinutes} minute`;
			break;
		default:
			minuteLabel = `${diffMinutes} minutes`;
			break;
	}

	return minuteLabel;
};

/**
 * Calculates the difference in current date and update date
 * @param {String} updateDate The update dates
 * @param {boolean} isMini True if mini update in article, false otherwise.
 * @param {boolean} isTop True if was in the top of article, false otherwise.
 * @return {Object} An object with hours and label
 */
const setDateCalculations = (updateDate = '', isMini = false, isTop = false) => {
	const currentDate = setEstDate();
	const updatedDate = setEstDate(updateDate);
	const diffMs = currentDate - updatedDate;
	const diffDays = Math.floor(diffMs / 86400000);
	const diffHours = Math.floor((diffMs % 86400000) / 3600000);
	const diffMinutes = Math.round(((diffMs % 86400000) % 3600000) / 60000);
	const hourLabel = `${diffHours} hour${diffHours !== 1 ? 's' : ''} and `;
	const minuteLabel = setMinuteLabel(diffMinutes);
	const updateLabel = `${isMini || !isTop ? '' : 'Updated'} ${diffHours ? hourLabel : ''}${diffHours > 0 && diffMinutes === 0 ? '' : minuteLabel} ${minuteLabel !== 'Just now' ? 'ago' : ''}`;

	return {
		diffDays,
		diffHours,
		updateLabel,
	};
};

/**
 * Creates and Adds the Live Update Label if difference is over 4 hours
 * @param {HTMLDocument} context The HTML element for a specific page
 * @param {Number} liveUpdate The live update time
 */
const setLiveUpdate = (context = document, liveUpdate = null) => {
	if (!liveUpdate) {
		return;
	}

	const { diffDays = 0, diffHours = 4, updateLabel = '' } = setDateCalculations(Number.parseInt(liveUpdate, 10), false, true);
	if (diffDays || diffHours > 4) {
		return;
	}

	const updateClass = 'content-data__updated-label';
	const updatedTimeLabel = createSimpleElement('div', {
		class: !diffHours ? `${updateClass} time--red` : updateClass,
		style: `${!diffHours ? 'color: #D8361E;' : ''} display: inline-block;`,
	});
	updatedTimeLabel.innerText = updateLabel;

	removeExistingTimeMetrics(context);
	context.insertBefore(updatedTimeLabel, context.firstChild);
};

/**
 * Inserts pageviews onto the article page
 * @param {HTMLDocument} context The HTML element for a specific page
 */
const setLiveTemplateMetrics = (context = document) => {
	const metrics = ((context.classList || {}).value || '').includes('content-data--hidden') ? context : context.querySelector('.content-data--hidden');
	const recentArticle = Array.from(document.querySelectorAll('article')).pop();
	const recentUpdate = recentArticle.querySelector('.live-update');

	if (!metrics) {
		return;
	}

	const liveExpiration = metrics.getAttribute('data-date-expiration');
	const currentDate = setEstDate();
	const expirationDate = setEstDate(Number.parseInt(liveExpiration, 10));

	if (expirationDate.getTime() > currentDate.getTime()) {
		const liveUpdate = recentUpdate.getAttribute('data-sort-time') || '';
		setLiveLabel(metrics);
		setLiveUpdate(metrics, liveUpdate);
	}

	metrics.classList.remove('content-data--hidden');
};

/**
 * Update the dates in mini stories to the correct text.
 * @param {String} templateType the template type of the content.
 * @param {HTMLNode} element The element to update.
 */
function updateMiniPublishDates(templateType = '', context = document) {
	const liveExpiration = context.querySelector('.content-data').getAttribute('data-date-expiration');
	const currentDate = setEstDate();
	const expirationDate = setEstDate(Number.parseInt(liveExpiration, 10));

	if (templateType !== 'live' || (expirationDate.getTime() <= currentDate.getTime())) {
		return;
	}

	const updates = Array.from(context.querySelectorAll('.live-update-timestamp'));
	updates.forEach((update) => {
		const timestamp = update.parentNode.getAttribute('data-sort-time');

		const { diffDays = 0, diffHours = 4, updateLabel = '' } = setDateCalculations(Number.parseInt(timestamp, 10), true, false);

		if (!diffDays && diffHours < 4) {
			update.textContent = updateLabel;
			update.style.display = 'block';

			if (diffHours < 2) {
				update.style.color = '#D8361E';
			}
		}
	});
}

/**
 * Fetches pageviews from tamagotchi for a specific article
 * @param {String} articleId The id for a specific article
 */
function fetchPageviews(articleId) {
	return fetch(`/tamagotchi/v1/fetchLifetimeViews/?id=${articleId}`)
		.then((res) => res.json())
		.then((data) => ((data || {}).views || -1))
		.catch((e) => console.error(`Error fetching pageviews for article ${articleId}:`, e) || -1);
}

/**
 * Inserts pageviews onto the article page
 * @param {Number} pageviews Number of pageviews
 * @param {HTMLDocument} context The HTML page for a specific article
 */
function insertPageViews(pageviews, context = document) {
	const isHiddenForLive = context.querySelector('.content-data--hidden');
	if (pageviews < 0) {
		if (isHiddenForLive) {
			setLiveTemplateMetrics(isHiddenForLive);
		}

		return;
	}

	const insert = context.querySelector('.pageviews-wrapper');
	const vertPipes = context.querySelectorAll('.hidden.vert-pipe');
	const pageViewsPipe = Array.from(vertPipes).pop();
	const newPageviews = document.createElement('span');

	newPageviews.classList.add('pageviews');

	if (pageViewsPipe) {
		pageViewsPipe.classList.remove('hidden');
	}

	newPageviews.innerHTML = `${pageviews.toLocaleString()} views`;
	insert.parentNode.insertBefore(newPageviews, insert);

	if (isHiddenForLive) {
		setLiveTemplateMetrics(isHiddenForLive);
	}
}

/**
 * Update the various parts of the meta so simple reach can pull the right data.
 * @param {String} authorName The author of the article that has been loaded in the stream.
 * @param {String} url The url of the article that has been loaded in the stream.
 */
function updateMetaForSimpleReach(authorName = '', url = '') {
	const canonicalLink = document.querySelector('link[rel="canonical"]');
	const authorMeta = document.querySelector('meta[property="article:author"]');

	if (canonicalLink) {
		canonicalLink.setAttribute('href', url);
	}

	if (authorMeta) {
		authorMeta.setAttribute('content', authorName);
	}
}

/**
 * Captures the search parameters on the window and filters for utm values
 * @returns {String} String of utm parameters from the window location
 */
const getUtmParams = () => {
	const params = new URL(window.location.href || '').searchParams;
	return Object.keys(Object.fromEntries(params))
		.reduce((acc, key) => {
			if (!key.includes('utm_')) {
				return acc;
			}

			acc = `${acc}${acc ? '&' : ''}${key}=${Object.fromEntries(params)[key]}`;
			return acc;
		}, '');
};

/**
 * Hides iframe until it is loaded
 */
function iframeArticleScroll() {
	const bodyContainer = document.querySelector('.article-body-container');

	if (!bodyContainer) {
		return;
	}

	if (document.readyState !== 'complete') {
		document.addEventListener('readystatechange', () => {
			bodyContainer.classList.add('show-iframes');
		});
	}
}

if (!isMobile) {
	document.addEventListener('DOMContentLoaded', iframeArticleScroll);
}

/**
 * Converts uri protocol to https
 * @param {String} uri that may have http protocol
 * @returns {String} Uri with https protocol
 */
function convertUriToHttps(uri = '') {
	return (uri).match(/^http:\/\//i) ? (uri).replace('http://', 'https://') : uri;
}

export {
	fetchPageviews,
	getUtmParams,
	updateMiniPublishDates,
	insertPageViews,
	updateMetaForSimpleReach,
	convertUriToHttps,
};
