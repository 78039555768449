/**
 * Prevent the current article from being displayed in recirc unit,
 * And slice array to desired limit
 * @param {Object[]} relatedArticles Array of articles
 * @param {String} articleUri the current article uri
 * @param {Number} limit size of the array to return
 * @returns {Object[]} Data for the recirc unit
 */
const filterArticles = (relatedArticles = [], articleUri = '', limit = 1) => relatedArticles
	.filter((article) => !!article.uri && article.uri.toLowerCase().replace('https', 'http') !== articleUri.toLowerCase().replace('https', 'http'))
	.slice(0, limit);

/**
 * Get the limit of articles to display on advisor widget recirc module
 * @param {String} widgetId type of advisor widget
 * @returns {Number} limit for number of articles to display in advisor widget module
 */
function getAdvisorLimit(widgetId = '') {
	if (widgetId === '4') {
		return 3;
	}
	return ['1', '3'].includes(widgetId) ? 1 : 2;
}

/**
 * Get the name to display for advisor widget click tracking and cd 106 value
 * @param {String} widgetId type of advisor widget
 * @returns {String} advisor recirc click track and cd 106 value
 */
function getAdvisorTrackingName(widgetId = '') {
	const widgetNames = [undefined, 'Single Article', 'Multiple Articles', 'Single Link', 'Multiple Links'];
	const widgetName = widgetNames[widgetId] ? ` - ${widgetNames[widgetId]}` : '';
	return `Advisor Recirc${widgetName}`;
}

module.exports = {
	filterArticles,
	getAdvisorLimit,
	getAdvisorTrackingName,
};
