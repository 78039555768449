import { FbsCarouselComponent } from '@forbes/fbs-carousel';

// Functionality for the related/key connections module on company and team pages.
customElements.define('fbs-carousel', FbsCarouselComponent);
document.addEventListener('DOMContentLoaded', () => {
	function carouselNavigateByDotsCompany(dots, carousel) {
		dots.forEach((dot, index) => {
			dot.addEventListener('click', () => {
				dots.forEach((carouselDot) => carouselDot.classList.remove('active'));
				dot.classList.add('active');
				carousel.goToIndex(index);
			});
		});
	}

	const carouselConnectionsCompany = document.getElementsByClassName('profile-connections__slider')[0];
	const connectionCompanyCarouselDots = [...document.querySelectorAll('.profile-connections .dots')];

	if (carouselConnectionsCompany) {
		carouselConnectionsCompany.addEventListener('slideChanged', (event) => {
			connectionCompanyCarouselDots.forEach((dot) => {
				dot.classList.remove('active');
			});
			const indexOffSet = Math.ceil((event.detail.currentSlide.scope.index));
			connectionCompanyCarouselDots[indexOffSet].classList.add('active');
		});
	}

	carouselNavigateByDotsCompany(connectionCompanyCarouselDots, carouselConnectionsCompany);
});
