document.addEventListener('DOMContentLoaded', () => {
	const socialContainer = document.querySelector('.video__social');
	const socialButtonsExceptEmail = [...socialContainer.querySelectorAll('.social-share__icon')].slice(0, 3);

	socialButtonsExceptEmail.forEach((social) => {
		social.onclick = (e) => {
			e.preventDefault();
			window.open(social.href, social.dataset.src, 'width=400,height=500,resizable=1');
		};
	});
});
