import FbsCordialService from '@forbes/fbs-cordial/dist/fbsCordialService';

/**
 * Sets up the cordial service for the specified page type,
 * if one does not already exist
 * @param {String} pageType
 */
function setupCordialService(pageType) {
	window.fbsCordial = window.fbsCordial || new FbsCordialService();
	window.fbsCordial.pageType = pageType;
	window.fbsCordial.getUserCordialData(); // populates the dataLayer
}

export default setupCordialService;
