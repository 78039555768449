import { isMobile } from './is-mobile';
import { getCookie } from '../../../shared/cookieUtils';

// TODO Remake for arbitrary data
export const serverData = ((window.forbes || {})['simple-site'] || {});

// @TODO Refactor client config service to work with stream (eg class with dynamic getters).
export const {
	adExperience,
	adId,
	adInventory,
	adZone,
	advoiceBrand,
	authorSlug,
	barChartYears,
	blogSlug,
	bucket,
	categoryCookieName,
	channelColor,
	channelId,
	channelName,
	cookieName,
	currentTabName,
	division,
	hasWriters,
	is404,
	isAdLight,
	isBlog,
	isE2E,
	isLocal,
	isProd,
	isPreview,
	isSingleRec,
	lazyLoadData,
	name,
	naturalId,
	pageLocation,
	premiumProfile,
	playerId,
	premiumPage,
	region,
	relativeVideo,
	relativeVideos,
	retracted,
	sectionId,
	slug,
	showFollowButton,
	specialSlot,
	streamIndex,
	streamSourceBlog,
	streamSourceType,
	streamSourceValue,
	subBlog,
	swimLane,
	topStories,
	tracking,
} = serverData;

// isEurope is needed early in the page for GDPR
export const { isEurope } = window.forbes || {};

export function isSponsored() {
	const { swimLane: swimlane, specialSlot: specialslot } = (window.forbes || {})['simple-site'] || {};
	return swimlane || specialslot;
}

/**
 * Updates gamzone with the logged in / subscribed state of the user as applicable.
 * @param {String} [gamZone='']
 * @returns {String} gamZone with logged in / subscription state set
 */
export function updateGamzoneUserState(gamZone = '') {
	if (!getCookie(document.cookie, '__utp')) {
		return gamZone;
	}

	return gamZone.replace(/default/, getCookie(document.cookie, '__tac') && (getCookie(document.cookie, '__fbs_tac') || '').indexOf('R8W03AS') > -1 ? 'subscriber' : 'nonsubscriber');
}

// @TODO 3646: We need consistent values both when building and scrolling through the stream.
export function getTracking() {
	const trackingData = ((window.forbes || {})['simple-site'] || {}).tracking;
	if ((trackingData || {}).pageType === '404') {
		trackingData.fastSu = `https://www.forbes.com${window.location.pathname}`;
	}

	trackingData.gamZone = updateGamzoneUserState(trackingData.gamZone);

	return trackingData;
}

export const adAccount = adInventory || '7175';
export const adSite = isMobile ? 'fdcmobile' : 'fdc.forbes';
export const fullAdZone = `/${adAccount}/${adSite}/${adZone}`;
