import checkStatus from '../../shared/checkStatus';

const articleUtilities = require('./articleUtilities.js');
const recircUtils = require('../../../../shared/recircUtils.js');

/**
 * Create the Advisor Widget Recirc block using recircData from gcp storage bucket
 * @param {String} widgetId widget type for this article
 * @param {Object[]} relatedArticles linked articles to be displayed within advisor widget module
 * @param {String} recircTrackingName advisor recirc click track and cd 106 value
 * @return {String} recircBlock inflated html with advisor widget recirc data
 */
function createRecircBlock(widgetId = '', relatedArticles = [], recircTrackingName = '') {
	let recircBlock = '';

	// determine click tracking attribute and class to use
	const { isAmp = false } = window.forbes['simple-site'];
	let trackingAttribute = 'data-ga-track';
	let ampGtmClass = '';
	if (isAmp) {
		trackingAttribute = 'data-vars-event-label';
		ampGtmClass = ' amp-recirc-unit';
	}

	if (widgetId === '1' || widgetId === '2') {
		recircBlock = relatedArticles.reduce((recircStory, nextStory, index) => {
			nextStory.uri = articleUtilities.convertUriToHttps(nextStory.uri);
			recircStory += `<div class="recirc-block-padding"><div class="recirc-content"><a class="recirc-link" href="${nextStory.uri}"><h3 class="recirc-headline-no-margin${ampGtmClass}" ${trackingAttribute}="${recircTrackingName} - Link ${index + 1}">${nextStory.title}</h3></a><a class="recirc-author" href="${nextStory.authorLink}">By<div class="recirc-author-name">${nextStory.authorName}</div><div class="recirc-author-type">${nextStory.authorRole}</div></a></div><a class="recirc-img${ampGtmClass}" style="background-image:url(${nextStory.articleImage});" href="${nextStory.uri}" ${trackingAttribute}="${recircTrackingName} - Link ${index + 1}"></a></div>`;
			return recircStory;
		}, '');
	} else {
		recircBlock = relatedArticles.reduce((recircStory, nextStory, index) => {
			nextStory.uri = articleUtilities.convertUriToHttps(nextStory.uri);
			recircStory += `<div class="recirc-block"><a class="recirc-link" href="${nextStory.uri}"><h3 class="recirc-headline${ampGtmClass}" ${trackingAttribute}="${recircTrackingName} - Link ${index + 1}">${nextStory.title}</h3></a></div>`;
			return recircStory;
		}, '');
	}
	return recircBlock;
}

/**
 * Process the advisor widget data retrieved from gcp storage bucket
 * @param {Object} json json format of the advisor widget data retrieved from gcp storage bucket
 * @returns {String} recirc block to insert into article body
 */
function processAdvisorWidgetData(json) {
	const {
		widgetId = '',
		relatedArticles = [],
		articlesUrl = '',
	} = json;

	const limit = recircUtils.getAdvisorLimit(widgetId);
	const recircTrackingName = recircUtils.getAdvisorTrackingName(widgetId);
	const filteredArticles = recircUtils.filterArticles(relatedArticles, articlesUrl, limit);
	return createRecircBlock(widgetId, filteredArticles, recircTrackingName);
}

/**
 * Update Advisor Widget html with most recent linked articles
 * @param {Object} recircData Data for the Advisor Widget recirc unit
 */
function updateAdvisorWidgetHTML(json = {}) {
	const advisorRecircWrapper = document.querySelector('.recirc-module .recirc-articles');

	if (advisorRecircWrapper) {
		const recircBlock = processAdvisorWidgetData(json);
		if (recircBlock) {
			advisorRecircWrapper.innerHTML = recircBlock;
		}
	}
}

/**
 * Get most recent linked articles to power in-article Advisor Widget Recirc Unit.
 */
function updateAdvisorWidgetRecircUnit() {
	const { isProd = '', fireAdvisorWidgetCall = false } = window.forbes['simple-site'];
	const { fastSu: uri = '' } = window.forbes['simple-site'].tracking;

	if (fireAdvisorWidgetCall && uri) {
		const bucket = isProd ? 'bacon-forbes-prd' : 'bacon-forbes-stg';
		fetch(`https://bacon.forbes.com/${bucket}/advisor-recirc-module/${Buffer.from(uri).toString('base64')}.json`)
			.then((response) => {
				checkStatus(response);
				response.json()
					.then((json) => {
						updateAdvisorWidgetHTML(json);
					});
			})
			.catch((error) => console.error(error));
	}
}

document.addEventListener('DOMContentLoaded', () => {
	updateAdvisorWidgetRecircUnit();
});
