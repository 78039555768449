import checkStatus from '../../shared/checkStatus';

const articleUtilities = require('./articleUtilities.js');

/**
 * Used to fire tracking event for SEO Automated Recirc content to determine the data source.
 * Currently the available sources are by mostPopular (chan/Sec) or valerie
 * @param {String} eventLabel the source of SEO Automated Recirc data
 */
function fireTrackingEvent(eventLabel = '') {
	window.dataLayer.push({
		event: 'analyticsEvent',
		'event category': 'Template Area Interaction',
		'event action': 'RecircData',
		'event label': eventLabel,
	});
}

/**
 * Create the SEO Automated Recirc block using recircData from Valerie
 * @param {Object[]} recircData seo data from Valerie
 * @return {String} recircBlock inflated html with seo recirc data from Valerie
 */
function createRecircBlock(recircData = []) {
	// only render 3 stories at max in SEO unit
	if (recircData.length > 3) {
		recircData = recircData.slice(0, 3);
	}

	// determine click tracking attribute and class to use
	const { isAmp = false } = window.forbes['simple-site'];
	let trackingAttribute = 'data-ga-track';
	let ampGtmClass = '';
	if (isAmp) {
		trackingAttribute = 'data-vars-event-label';
		ampGtmClass = ' amp-recirc-unit';
	}

	const recircBlock = recircData.reduce((recircStory, nextStory, index) => {
		nextStory.uri = articleUtilities.convertUriToHttps(nextStory.uri);
		recircStory += `<div class="recirc-block"><a class="recirc-link" href="${nextStory.uri}"><h3 class="recirc-headline${ampGtmClass}" ${trackingAttribute}="Collaborative Filtering - Automated Recirc - Link ${index + 1}">${nextStory.title}</h3></a></div>`;
		return recircStory;
	}, '');

	return recircBlock;
}

/**
 * Update SEO html with personalized content and fire tracking event
 * @param {Object[]} recircData Data for the SEO recirc unit
 */
function updateSEOUnitHTML(recircData = []) {
	const seoRecircWrapper = document.querySelector('.recirc-module .recirc-articles');

	if (seoRecircWrapper) {
		const recircBlock = createRecircBlock(recircData);
		// if valerie content doesn't exist, then the SEO Automated Content source will be mostPopular
		let eventLabel = 'mostpopdataserved';
		if (recircBlock) {
			seoRecircWrapper.innerHTML = recircBlock;
			seoRecircWrapper.classList.add('valerie-bot-stories');
			eventLabel = 'collabfilteringdataserved';
		}
		fireTrackingEvent(eventLabel);
	}
}

/**
 * Get collaborative filtering via Valerie bot to power in-article SEO Recirc Unit.
 */
function updateSEORecircUnits() {
	const { naturalID = '' } = window.forbes['simple-site'].tracking;
	const { fireValerieCall = false } = window.forbes['simple-site'];

	if (fireValerieCall) {
		fetch(`https://valerie.forbes.com/recommend?algorithm=collaborative&naturalId=${naturalID}`)
			.then((response) => checkStatus(response))
			.then((res) => res.json())
			.then((data) => {
				updateSEOUnitHTML(data.result);
			})
			.catch((error) => console.error(error));
	}
}

document.addEventListener('DOMContentLoaded', () => {
	updateSEORecircUnits();
});
