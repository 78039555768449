import { FbsAdsService } from '@forbes/fbs-ads';
import { isMobile } from './is-mobile';
import { getBbgTerm, getFvid } from './tracking';
import { desktopAdConfig, mobileAdConfig } from '../adsConfig';
import {
	adId,
	advoiceBrand,
	currentTabName,
	fullAdZone,
	getTracking,
	isEurope,
	isE2E,
	isPreview,
	premiumProfile,
	isProd,
} from './clientConfigService';
import { getCookie } from '../../../shared/cookieUtils';
import { checkForNone, removeSpaceAndLowerCase, setChanSecParam } from './adService';

// The criteo zone id, currently only used on desktop
const criteoZoneId = 1168044;

// E2E tests are having issues on certain ad creative loads. Try to get amazon bidding test creatives instead
if (isE2E) {
	localStorage.setItem('apstagDebug', 'true');
}

/**
 * Determines if we should cal the Fuse API
 * @param {Object} params argument object holding the clientId of the current user.
 * @returns {Boolean} True if fuse call should be made
 */
function shouldCallFuse(params = {}) {
	const { tracking: { gamZone = '' } = {} } = (window.forbes || {})['simple-site'] || {};
	const isArticle = gamZone.includes('article');

	// Articles are calling fuse twice. This check ensures it does not.
	return (params || {}).clientId && (!isArticle || window.fuse);
}

/**
 * Sets a query string to append to the Fuse call
 * @returns {String} A query string made up of channel and section values
 */
function setFuseQueryString() {
	const { tracking = {} } = (window.forbes || {})['simple-site'] || {};
	const queryParams = {
		channel: setChanSecParam(tracking, 'channel'),
		section: setChanSecParam(tracking, 'section'),
	};

	return Object.keys(queryParams).map((key) => `${key}=${encodeURIComponent(queryParams[key])}`).join('&');
}

/**
 * Makes an external api call to fetch Fuse DFP key values for client specific ad targeting based on their views.
 * @param {Object} params argument object holding the clientId of the current user.
 */
function fetchFuseSegments(params = {}) {
	if (shouldCallFuse(params)) {
		const queryString = setFuseQueryString();
		return fetch(`https://fuse${isProd ? '' : '-dev'}.forbes.com/fuse/${params.clientId || ''}?${queryString}`)
			.then((res) => res)
			.then((res) => res.json())
			.catch((e) => {
				console.error('Fuse Segment Error:', e);
			});
	}
	return new Promise((resolve) => resolve(null));
}

// The current user's unique Id
const clientId = isE2E ? '00000000000000000000000000000000000' : ((getCookie(document.cookie, 'client_id') || '').split('=') || [])[1] || '';

// Make this API call before any ads logic.
const fuseSegmentsPromise = fetchFuseSegments({ clientId });
window.fuse = fuseSegmentsPromise;

window.fbsads = window.fbsads || new FbsAdsService();
const adService = window.fbsads;
// Let's make this reassignable for use cases where we need to update the zone for lazy loaded content.
let currentAdZone = fullAdZone;

/**
 * Detects whether there is a slot on the ads config for the given ad id.
 * @param {String} id the ad id of the slot
 * @returns {Boolean}
 */
export function isActiveAdSlot(id) {
	return (window.fbsads || {}).adSlots instanceof Map && Array.from(window.fbsads.adSlots.keys()).indexOf(id) >= 0;
}

/**
 * Returns a function to compare a given ad size to the targetAdSize, returning true if they are equivalent.
 * @param {Array|string} targetAdSize
 */
function compareToAdSize(targetAdSize) {
	const isArray = Array.isArray(targetAdSize);
	const { length } = targetAdSize;
	return (size) => (isArray
		? Array.isArray(size) && length === size.length && targetAdSize.every((value, index) => value === size[index])
		: targetAdSize === size);
}

// @TODO AN-3117 Refactor modification of adsConfig and audit usage by different pages in SS
const isSevenByOneSize = compareToAdSize([7, 1]);
const isSevenByTwoSize = compareToAdSize([7, 2]);
// remove [7, 1] and [7, 2] top ad size for standard desktop articles, and for gallery pages
const desktopPagesTopAdSizes = desktopAdConfig.positions.top.sizes.filter((size) => !isSevenByOneSize(size) && !isSevenByTwoSize(size));

/**
 * Return the "ss" (swimlane) query parameter if it exists.
 * @return {undefined|String}
 */
export function getSwimlane() {
	const queryParams = window.location.search.replace('?', '').split('&');
	const swimlaneParams = queryParams.map((param) => param.split('='))
		.find((item) => item[0] === 'ss' && item[1]);

	return swimlaneParams && swimlaneParams[1];
}

let desktopConfig;
let mobileConfig;

/**
 * Chooses the correct ad config for a template and allows mobile article ads to get the right templat
 * @param {boolean} isMobile True if the client is using a mobile device.
 * @returns {Object} The ad config to use.
 */
function getConfig() {
	const isMobilePreview = isPreview && window.location.pathname.indexOf('/preview/mobile') >= 0;
	return isMobile || isMobilePreview ? mobileConfig : desktopConfig;
}

/**
 * Sets the window external_services object for MOAT/mnet. Supposedly the ad targeting data isn't enough.
 * @param {*} config GPT configuration object for fbs-ads
 */
function setExternalServices(config) {
	config.params = config.params || {};
	const siteZone = (config.ad_unit_path || '').split('/') || [];
	const { articleId = '' } = window.forbes['simple-site'];

	const externalParams = {
		site: siteZone[2],
		zone: (siteZone.slice(3) || []).join('/'),
		author: config.additionalMoatParams.author || '',
		brandvoice: ['ad', 'advoice'].indexOf(config.params.type) > -1,
		channel: config.params.channel,
		editorialSlot: config.params.editSlot,
		hashtags: config.params.ht,
		section: config.params.section,
		specialSlot: config.params.specialslot,
	};

	if (articleId) {
		externalParams.current_article_id = articleId;
	}

	window.external_services = Object.assign((window.external_services || {}), externalParams);
}

// This function is being called twice in the first article in the stream, should probably open a new ticket
// It's being called once thro this file and It's being called again in streamUtilities -> changeArticle
// We should probably add a check if this article index is 0 not to call this function in changeArticle
export function updateAdParams() {
	const tracking = getTracking();
	const swimlaneUrl = advoiceBrand ? '' : getSwimlane();
	// @TODO: Rework client config service to work well with the stream.
	const swimLaneValue = ((window.forbes || {})['simple-site'] || {}).swimLane || swimlaneUrl;
	const adInventory = ((window.forbes || {})['simple-site'] || {}).adInventory || '7175';
	const sentimentScore = ((window.forbes || {})['simple-site'] || {}).sentimentScore || '';
	const adcount = (((window.forbes) || {})['simple-site'] || {}).adcount || 0; // mobile only
	const tab = currentTabName || '';

	// makes sure the ad zone stays correct for articles loaded in the stream
	if ((tracking.gamZone || '').indexOf('article') > -1) {
		currentAdZone = `/${adInventory}/${isMobile ? 'fdcmobile' : 'fdc.forbes'}/${tracking.gamZone}`;
	}

	const params = {
		// ab: getAbParam(), TODO Commented until AdOps decide Ticket UEM-411
		author: removeSpaceAndLowerCase(checkForNone(tracking.isGroupBlog ? tracking.publicationAuthor : tracking.author)),
		bbgterm: getBbgTerm(),
		channel: [setChanSecParam(tracking, 'channel')],
		editSlot: tracking.edit || '',
		fvid: getFvid(),
		ht: checkForNone(tracking.hashtags),
		id: adId || tracking.naturalID || '', // allows adops to specifically target ad sizes to these pages
		login: tracking.login || false,
		section: [setChanSecParam(tracking, 'section')],
		specialslot: swimLaneValue ? '' : (tracking.slot || ''),
		swimlane: swimLaneValue,
		tab,
		templatetype: tracking.templateType,
		type: removeSpaceAndLowerCase(checkForNone(tracking.contribType || tracking.type)),
		badges: tracking.badges,
		advoice: tracking.brandVoice, // Update ad params to match the current article whenever the current article changes.
		mnet_adi: '',
		sentimentScore,
	};

	if (premiumProfile) {
		params.premiumProfile = premiumProfile;
	}

	const additionalMoatParams = {
		author: checkForNone(tracking.isGroupBlog ? tracking.publicationAuthor : tracking.author),
	};

	if (tracking.editorsPick) {
		params.ep = tracking.editorsPick;
	}

	if (tracking.coverStory) {
		params.coverstory = tracking.coverStory;
	}

	if (tracking.bertieBadgeSlugs) {
		params.badges = tracking.bertieBadgeSlugs;
	}

	if (tracking.negativeSentimentCompanies) {
		params.ns = tracking.negativeSentimentCompanies;
	}

	if ((tracking.entitySegments || []).length > 0) {
		params.es = tracking.entitySegments.join(',');
		params.essrc = tracking.entitySegments.map((segment) => `${window.fbsads.getTargetingSource() ? `${window.fbsads.getTargetingSource()}_` : ''}${segment}`).join(',');
	}

	if (tracking.sentimentCompanies) {
		params.co = tracking.sentimentCompanies;
	}

	// Special slot or swimlane beat all
	if (params.specialslot || params.swimlane) {
		params.channel = '';
		params.section = '';
		params.displaychannel = '';
		params.displaysection = '';
		if (params.swimlane) {
			params.specialslot = '';
		}
	}

	// update the params in the promise in case bootstrapping starts in hopes the fuse data gets set before dfp key values are
	// sent to the ads server.
	fuseSegmentsPromise.then((fuseData) => {
		if (!Object.keys(fuseData || {}).length) {
			return;
		}
		const { bertieBadgeSlugs = '' } = tracking || {};
		const isDecisionMakerContent = bertieBadgeSlugs.indexOf('decisionmaker') > -1 || bertieBadgeSlugs.indexOf('decision-maker') > -1;
		const { kvs = {} } = fuseData;

		if (Object.keys(kvs).length) {
			Object.keys(kvs).forEach((key) => {
				params[key] = kvs[key];
			});
		}

		if (isDecisionMakerContent && params.jt !== '1') {
			params.jt = '1.1';
		}
	});

	desktopConfig = {
		...desktopAdConfig,
		bypass_validate_moat_yield: isE2E,
		params,
		additionalMoatParams,
		ad_unit_path: currentAdZone,
		isEurope,
	};

	mobileConfig = {
		...mobileAdConfig,
		bypass_validate_moat_yield: isE2E,
		params: {
			...params,
			adcount,
		},
		additionalMoatParams,
		ad_unit_path: currentAdZone,
		isEurope,
	};

	const isDesktopArticle = tracking.dfpZone.indexOf('article') > -1;
	const isGalleryDesktopPage = tracking.dfpZone.indexOf('pictures') > -1;

	// @TODO AN-3117 Refactor this
	// This needs to happen here before the first article top ad slot is created.
	if ((isDesktopArticle || isGalleryDesktopPage) && !isMobile) {
		if (desktopConfig.positions.top.sizes) {
			desktopConfig.positions.top.sizes = desktopPagesTopAdSizes;
		}

		if (desktopConfig.positions.recx && !isGalleryDesktopPage) {
			desktopConfig.positions.recx.criteoZoneId = criteoZoneId;
		}
	} else if (isMobile && tracking.dfpZone.indexOf('article') > -1) {
		const mobileParams = {
			strnativekey: '13b84c32',
		};

		if (mobileConfig.positions.mobilerec) {
			mobileConfig.positions.mobilerec.params = mobileParams;
		}

		if (mobileConfig.positions.mobilex) {
			mobileConfig.positions.mobilex.params = mobileParams;
		}
	}
}

/**
 * Allows specialSlot and adsConfig configuration update after adService is bootstrapped.
 * @param {String} specialSlot special slot value.
 * @param {Boolean} noHeroMobile true if we want to remove hero sizes ([7, 1]) from the mobile position.
 */
export function applyConfig(specialSlot, noHeroMobile = false) {
	const config = getConfig();

	if (specialSlot !== false) {
		config.params.specialslot = specialSlot;
	}

	// remove the [7, 1] and [7, 2] ad size from the mobile position on the mobileConfig.
	if (noHeroMobile && config.positions.mobile.sizes) {
		config.positions.mobile.sizes = config.positions.mobile.sizes.filter((size) => !isSevenByOneSize(size) && !isSevenByTwoSize(size));
	}

	setExternalServices(config);
	adService.applyConfig(config);
}

updateAdParams();
setExternalServices(getConfig());
adService.bootstrap(getConfig());
