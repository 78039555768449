import { serverData, adId } from '../shared/clientConfigService';
import checkStatus from '../shared/checkStatus';
import Event from '../shared/event';

const { numOfGrids } = serverData;

/**
 * Sets up handler for loading additional grid sections should they exist.
 */
document.addEventListener('DOMContentLoaded', () => {
	const buffer = 200;
	const gridContainer = document.querySelector('.grid');
	const playlistId = adId.split('/')[1];
	let fetching = false;
	let numOfGridsLoaded = 0;

	/**
	 * Checks if there are additional grid sections to retrieve from the api.
	 */
	function getGrid() {
		if (!fetching && numOfGrids > numOfGridsLoaded) {
			fetching = true;
			const nextGridSection = numOfGridsLoaded + 1;

			fetch(`/simple-data/video/grid?playlistId=${escape(playlistId)}&page=${escape(nextGridSection)}`)
				.then((response) => checkStatus(response))
				.then((res) => res.text())
				.then((res) => {
					const wrapper = document.createElement('div');
					wrapper.innerHTML = res;
					gridContainer.append(wrapper);
					numOfGridsLoaded++;
					fetching = false;

					const gridResponseEvent = new CustomEvent('fbs-grid-response', {
						detail: wrapper,
					});
					document.dispatchEvent(gridResponseEvent);
					if (numOfGrids === numOfGridsLoaded) {
						/* eslint-disable no-use-before-define */
						Event.removeEventListener('scroll.grid', onScroll);
					}
				});
		}
	}

	/**
	 * Checks to see how far the user is in the page and fires getGrid() if
	 * threshold is met.
	 */
	function onScroll() {
		if (gridContainer.clientHeight + gridContainer.offsetTop < window.pageYOffset + window.innerHeight + buffer) {
			getGrid();
		}
	}

	// Set up the scroll handler if there are more grid sections to be loaded.
	if (numOfGrids > numOfGridsLoaded) {
		Event.addEventListener('scroll.grid', onScroll);
	}
});
