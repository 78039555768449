import { isMobile, isTablet } from './is-mobile';
import { adObservable } from './adInsertion';
import { getCookie } from '../../../shared/cookieUtils';
import {
	channelName,
	channelColor,
	premiumPage,
	isProd,
} from './clientConfigService';

const svgs = require('@forbes/fbs-icons/dist/index');

const superAdminResource = 'RXR0D16';
const adminResource = 'RO8HSE9';
const topAdvisorResource = ['R0EQIX2', adminResource, superAdminResource];

document.addEventListener('DOMContentLoaded', () => {
	const header = document.querySelector('.header');
	const headerNav = document.querySelector('.header__nav');
	const hamburgerIcon = header.querySelector('.icon--hamburger');
	const exploreText = header.querySelector('.explore-text');
	const closeIcon = header.querySelector('.icon--close');
	const headerNavOpenClass = 'header__nav--is-open';
	const headerNavCloseClass = 'header__nav--is-closed';
	const loginButton = header.querySelector('.login');
	const loginSection = header.querySelector('.login__section');
	const channelLiElements = [...document.querySelectorAll('.header__channel')];
	const sectionLiElements = [...document.querySelectorAll('.header__section')];
	const headerSubnavElements = [...document.querySelectorAll('.header__subnav')];
	const channelAnchorSpanElements = [...document.querySelectorAll('.header__title')];

	const subnav = document.querySelector('.header__subnav');

	const searchIcon = header.querySelector('.icon--search');
	const lastChannel = document.querySelector('.header__channels').lastChild;

	const headerChannelLinkOpen = 'header__channel--is-open';
	const headerChannelLinkClose = 'header__channel--is-closed';

	const body = document.querySelector('body');
	const bodyPreventScrolling = 'body__prevent-scrolling';
	let scrollPosition;

	/**
	 * Checks piano resource cookie access
	 * @param {String} resourceCookie user resources
	 * @param {Array} permittedResources checked resources
	 * @returns {Object} Access Piano Response
	 */
	function checkResourceAccessByCookie(resourceCookie = '', permittedResources = []) {
		const userResources = (decodeURIComponent(resourceCookie).split('=')[1] || '').split(',');

		let resMessage = { error: true, message: 'User not permitted.' };
		userResources.forEach((record) => {
			if (permittedResources.includes(record)) {
				resMessage = { success: true, resource: record };
			}
		});

		return resMessage;
	}

	/**
	 * Builds greeting span for login menu to append dropdown content
	 * @param {String} userFirstName User's name that comes from stored piano info
	 * @returns {HTMLElement} dropdown to append content
	*/
	function buildDropdownElement(userFirstName, userEmail) {
		const dropdown = document.createElement('span');
		dropdown.classList.add('loggedin-dropdown');
		dropdown.innerHTML = `<span class=greeting-text> Hi, ${userFirstName || userEmail || ''} </span><span class=login-chevron>${svgs.chevronDown}</span>`;
		return dropdown;
	}

	/**
	 * Builds dropdown div for login menu
	 * @returns {HTMLElement} dropdownContent div to append buttons
	*/
	function buildDropdownContent() {
		const dropdownContent = document.createElement('div');
		dropdownContent.classList.add('dropdown-content');
		return dropdownContent;
	}

	/**
	 * Builds signout button for login menu
	 * @returns {HTMLElement} signoutButton
	*/
	function buildSignoutButton() {
		const signoutButton = document.createElement('button');
		signoutButton.classList.add('signout-button');
		signoutButton.innerText = 'Sign Out';
		return signoutButton;
	}

	/**
	 * Builds new link buttons for login menu
	 * @param {String} newLinkButtonName string passed to create href, innerText, and className
	 * @returns {HTMLElement} helpButton
	*/
	function buildLinkButton(newLinkButtonName) {
		const newLinkButton = document.createElement('a');
		newLinkButton.classList.add(`${newLinkButtonName}-button`);
		newLinkButton.href = `https://${isProd ? '' : 'staging-'}account.forbes.com/${newLinkButtonName}`;
		newLinkButton.target = '_blank';
		newLinkButton.rel = 'noopener noreferrer';

		// profile and contact preferences buttons differ from routes/classNames
		if (newLinkButtonName === 'account') {
			newLinkButton.innerText = 'Account';
			newLinkButton.href = `https://${isProd ? '' : 'staging-'}account.forbes.com/profile`;
		} else if (newLinkButtonName === 'profile') {
			newLinkButton.innerText = 'Profile';
			newLinkButton.href = `https://${isProd ? '' : 'staging-'}account.forbes.com/${newLinkButtonName}/list`;
		} else if (newLinkButtonName === 'contact-preferences') {
			newLinkButton.innerText = 'Contact Settings';
		} else {
			newLinkButton.innerText = `${newLinkButtonName.replace(/-/g, ' ').replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase())}`;
		}

		return newLinkButton;
	}

	/**
	 * Toggles dropdown menu open and closed
	 * @param {HTMLElement} dropdownContent The dropdown content that is displayed/hidden on toggle
	*/
	function toggleDropdown(dropdownContent) {
		const arrow = header.querySelector('.fs-icon--chevron-down');
		arrow.classList.toggle('rotate');
		dropdownContent.classList.toggle('show');
	}

	/**
	 * Renders login menu with dropdown and functionality if user is logged in
	 * @param {String} userFirstName Comes from stored piano id object
	*/
	function renderLoginMenu(userFirstName, userEmail) {
		const loginMenu = header.querySelector('.login__menu');
		const dropdown = buildDropdownElement(userFirstName, userEmail);
		const dropdownContent = buildDropdownContent();
		const contactPreferencesButton = buildLinkButton('contact-preferences');
		const followingButton = buildLinkButton('following');
		const helpButton = buildLinkButton('help');
		const newslettersButton = buildLinkButton('newsletters');
		const signoutButton = buildSignoutButton();
		const accountButton = buildLinkButton('account');
		const profileButton = buildLinkButton('profile');
		const userResource = checkResourceAccessByCookie((getCookie(document.cookie, '__fbs_tac') || ''), topAdvisorResource);

		loginSection.remove();
		loginMenu.appendChild(dropdown);
		dropdown.appendChild(dropdownContent);
		dropdownContent.appendChild(accountButton);
		if ((userResource || '').success && profileButton) {
			dropdownContent.appendChild(profileButton);
		}
		dropdownContent.appendChild(newslettersButton);
		dropdownContent.appendChild(contactPreferencesButton);
		dropdownContent.appendChild(followingButton);
		dropdownContent.appendChild(helpButton);
		dropdownContent.appendChild(signoutButton);

		dropdown.addEventListener('click', () => {
			toggleDropdown(dropdownContent);
		});

		signoutButton.addEventListener('click', () => {
			const { tp: { pianoId = {} } = {} } = window;
			pianoId.logout();
			window.location.reload(true);
			// TODO: After UEM-470 is out and approved by David
			// set this cookie with expiration before you reload the page.
			// var d = new Date(); d.setTime(d.getTime() + 5 * 1000);
			// expires 5 seconds from now
			// document.cookie = "lastPagePath={{Page Path}};" + " expires=" + d.toUTCString() + "; path=/";
		});
	}

	document.addEventListener('fbs-piano-init', () => {
		const { tp: { pianoId = {} } = {} } = window;
		if (pianoId.isUserValid()) {
			const userFirstName = pianoId.getUser().firstName;
			const userEmail = pianoId.getUser().email;
			renderLoginMenu(userFirstName, userEmail);
		} else {
			loginSection.classList.add('show');
		}
	});

	document.addEventListener('fbs-tac-init', () => {
		const { tp: { pianoId = {} } = {} } = window;
		if (pianoId.isUserValid()) {
			const userResource = checkResourceAccessByCookie((getCookie(document.cookie, '__fbs_tac') || ''), topAdvisorResource);
			// Update TAC navbar options if delay happened in fbs-piano-init
			if ((userResource || '').success) {
				const dropdownContent = document.querySelector('.dropdown-content');
				if (dropdownContent) {
					const accountButton = dropdownContent.querySelector('.account-button');
					let profileButton = dropdownContent.querySelector('.profile-button');
					if ((userResource || '').success && profileButton) {
						profileButton = buildLinkButton('profile');
						accountButton.after(profileButton);
					}
				}
			}
		}
	});

	function handleOpenHamburger() {
		headerNav.classList.remove(headerNavCloseClass);
		headerNav.classList.add(headerNavOpenClass);
		body.classList.add(bodyPreventScrolling);
		scrollPosition = window.pageYOffset;
	}

	if (hamburgerIcon) {
		hamburgerIcon.addEventListener('click', () => {
			handleOpenHamburger();
		});

		hamburgerIcon.addEventListener('keyup', (e) => {
			if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
				handleOpenHamburger();
			}
		});
	}

	if (exploreText) {
		exploreText.addEventListener('click', () => {
			handleOpenHamburger();
		});
	}

	function handleCloseIcon() {
		headerNav.classList.remove(headerNavOpenClass);
		headerNav.classList.add(headerNavCloseClass);
		body.classList.remove(bodyPreventScrolling);
		window.scrollTo(0, scrollPosition);
	}

	if (closeIcon) {
		closeIcon.addEventListener('click', () => {
			handleCloseIcon();
		});

		closeIcon.addEventListener('keyup', (e) => {
			if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
				handleCloseIcon();
			}
		});
	}

	/**
	* @function showPianoElement
	* When Login button is clicked, piano window will open for user login.
	* @param {String} windowName the name of the piano window that should be visible
	* @return null
	*/
	function showPianoElement(windowName) {
		const { tp: { pianoId = {} } = {} } = window;
		if (pianoId) {
			pianoId.show({ screen: windowName });
		}
	}

	/**
	 * tracking for when the user clicks login button
	 * @param {String} trackingAction tracking information based on button clicked
	 */
	function trackSignInClicks(trackingAction) {
		window.dataLayer.push({
			event: 'analyticsEvent',
			'event category': 'Template Area Interaction',
			'event action': 'Click',
			'event label': `U20-${trackingAction}`,
		});
	}

	/**
	 * When Login/Create Account button is clicked, piano window will open for user login and send tracking data.
	 * @param {String} windowName the type of piano window to open
	 * @param {String} trackingAction tracking information based on button clicked
	*/
	function handleSignIn(windowName, trackingAction) {
		showPianoElement(windowName);
		trackSignInClicks(trackingAction);
	}

	if (loginButton) {
		loginButton.addEventListener('click', () => {
			handleSignIn('login', 'SignIn');
		});
	}

	function handleChannelLinkClick(e) {
		const openItem = document.querySelector(`.${headerChannelLinkOpen}`);
		const link = e.target.classList.contains('header__title') ? e.target.parentNode : e.target;
		const isChannel = link.classList.contains('header__channel');

		if (openItem && isChannel && !openItem.isSameNode(link)) {
			openItem.classList.remove(headerChannelLinkOpen);
		}

		if (link.classList.contains(headerChannelLinkOpen)) {
			link.classList.remove(headerChannelLinkOpen);
			link.classList.add(headerChannelLinkClose);
			link.setAttribute('data-ga-track', `U20 - Channel: ${link.dataset.title} - Position ${link.dataset.position} - Channel Expanded`);
			subnav.setAttribute('aria-hidden', 'true');
		} else {
			link.classList.remove(headerChannelLinkClose);

			// hide divider and subnav if there are no section links for channel
			if ([...link.querySelectorAll('.header__section')].length > 1) {
				link.classList.add(headerChannelLinkOpen);
			} else {
				link.classList.add('header__channel--no-sections');
			}

			link.removeAttribute('data-ga-track');
			subnav.setAttribute('aria-hidden', 'false');
		}
	}

	if (isMobile || isTablet) {
		channelLiElements.forEach((link) => {
			// handles tapping channels on mobile
			link.setAttribute('data-ga-track', `U20 - Channel: ${link.dataset.title} - Position ${link.dataset.position} - Channel Expanded`);
			link.addEventListener('click', (e) => {
				handleChannelLinkClick(e);
			});
		});
	} else {
		/**
		 * trigger hover states only on desktop using a class
		 * so that click events don't trigger hover styles on devices
		 */
		channelLiElements.forEach((link) => {
			// hide divider and subnav if there are no section links for channel
			if (link.classList.contains('header__channel')) {
				if ([...link.querySelectorAll('.header__section')].length > 1) {
					link.classList.add('header__hoverable');
				} else {
					link.classList.add('header__channel--no-sections');
				}
			} else {
				link.classList.add('header__hoverable');
			}

			// handles case when user is tabbing and then hovers on different item
			link.addEventListener('mouseenter', () => {
				const openItem = document.querySelector(`.${headerChannelLinkOpen}`);
				if (openItem) {
					openItem.classList.remove(headerChannelLinkOpen);
					document.activeElement.blur();
				}
			});
		});

		sectionLiElements.forEach((link) => {
			link.addEventListener('mouseenter', () => {
				const previouslySelected = document.querySelector('.mouse__enter');
				if (previouslySelected) {
					previouslySelected.classList.remove('mouse__enter');
				}
				link.classList.add('mouse__enter');
			});
		});

		headerSubnavElements.forEach((sub) => {
			sub.addEventListener('mouseleave', () => {
				const previouslySelected = document.querySelector('.mouse__enter');
				if (previouslySelected) {
					previouslySelected.classList.remove('mouse__enter');
				}
			});
		});

		channelAnchorSpanElements.forEach((link) => {
			// handles focus events/tabbing for accessibility on non devices
			link.addEventListener('focus', (e) => {
				handleChannelLinkClick(e);
			});
		});

		if (searchIcon) {
			searchIcon.addEventListener('focus', () => {
				lastChannel.classList.remove(headerChannelLinkOpen);
			});
		}
	}

	const topAdWrapper = document.querySelector('.fbs-ad--top-wrapper');
	const topAdStickyClass = 'fbs-ad--top-wrapper--sticky';
	const mainContentBodyBlock = document.querySelector('.main-content--body');

	function unstickyAd() {
		mainContentBodyBlock.classList.remove('main-content__body--animating');
		mainContentBodyBlock.style.paddingTop = 0;
		if (topAdWrapper && !premiumPage) {
			topAdWrapper.classList.remove(topAdStickyClass);
			topAdWrapper.style.height = 'auto';
		}
	}

	function updateNormalAndFluidAds(height) {
		if (topAdWrapper && !premiumPage) {
			mainContentBodyBlock.classList.add('main-content__body--animating');
			mainContentBodyBlock.style.paddingTop = `${height}px`;
			topAdWrapper.classList.add(topAdStickyClass);
			topAdWrapper.style.height = `${height}px`;
		}
	}

	const takeOverAdHeight = 7;
	let firstRun = true;

	function updateAdHeight(params) {
		const {
			height,
			isFluidAd,
			size = [],
		} = params;
		const parentElementId = topAdWrapper.parentElement.id;
		const isNotSticky = topAdWrapper.classList.contains('fbs-ad--top-wrapper--dont-stick');

		if (!parentElementId.match(/article-container-\d+/)) {
			if (height !== takeOverAdHeight && firstRun && size[0] !== takeOverAdHeight) {
				firstRun = false;
				const newHeight = isFluidAd ? height : height + 20;

				if (isNotSticky) {
					topAdWrapper.style.height = `${newHeight}px`;
					setTimeout(() => {
						topAdWrapper.style.height = 'auto';
					}, 5000);
				} else {
					updateNormalAndFluidAds(newHeight);
					setTimeout(() => {
						unstickyAd();
					}, 5000);
				}
			}
		}
	}
	adObservable.subscribe(updateAdHeight);

	if (isMobile) {
		mainContentBodyBlock.style.paddingTop = 0;
	} else if (topAdWrapper && !premiumPage) {
		topAdWrapper.classList.add('fbs-ad--top-wrapper--desktop');
	}

	/**
	 * Applies channel color to header.
	 * @param {Object} headerChannelItems querySelector of header items.
	 */
	function applyColor(headerChannelItems) {
		headerChannelItems.forEach((channel) => {
			if (channel.querySelector('.header__title').innerText === channelName) {
				channel.classList.add('header__current');
			}
		});
	}

	/**
	 * Highlight current channel color and removes color on hover and re-applies it on hover out.
	 */
	function handleHeaderHighlight() {
		if (!(channelName && channelColor)) {
			return;
		}

		const headerChannelItems = document.querySelectorAll('.header__channels .header__channel');

		headerChannelItems.forEach((channel) => {
			channel.addEventListener('mouseover', () => {
				const headerCurrentElement = document.querySelector('.header__current');
				if (headerCurrentElement) {
					headerCurrentElement.classList.remove('header__current');
				}
			});
			channel.addEventListener('mouseout', () => applyColor(headerChannelItems));
		});

		applyColor(headerChannelItems);
	}

	handleHeaderHighlight();
});
