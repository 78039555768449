let lastIndex = 14;
let loadMore;
let historicalQuotesRow;

function changeWrapperHeight() {
	const slides = document.querySelector('.fbs-slider__slides');
	const slidesWrapper = document.querySelector('.fbs-slider__slides-wrapper');

	slides.childNodes.forEach((tab) => {
		if (tab.classList.contains('active')) {
			slidesWrapper.style.height = `${tab.clientHeight + 10}px`;
		}
	});
}

function displayHistoricalQuotesData() {
	historicalQuotesRow.forEach((row, i) => {
		if (i >= lastIndex && i < lastIndex + 14) {
			row.classList.remove('hidden');
		}

		if (i === historicalQuotesRow.length - 1) {
			if (!row.classList.contains('hidden')) {
				loadMore.style.display = 'none';
			}
		}
	});

	changeWrapperHeight();
	lastIndex += 14;
}

document.addEventListener('DOMContentLoaded', () => {
	const coursel = document.querySelector('.fbs-slider');
	changeWrapperHeight();

	coursel.addEventListener('slideChanged', (event) => {
		event.detail.activeSlides.forEach((slide) => {
			if (slide.inView) {
				if (slide.slideData.scope.key === 'Historical Quotes') {
					loadMore = document.querySelector('.profile-datapoint__load-more');
					historicalQuotesRow = document.querySelectorAll('.datapoint-historical__data-row');

					loadMore.addEventListener('click', displayHistoricalQuotesData);
				}
			}
		});

		changeWrapperHeight();
	});
});
